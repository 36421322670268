type CountryMileageExpense = {
    unit: 'km' | 'mi';
    average_rate: number;
};

type MileageExpense = {
    [country: string]: CountryMileageExpense;
};

const mileageExpense: MileageExpense = {
    "US": {
        "unit": "mi",
        "average_rate": 0.66,
    },
    "CA": {
        "unit": "km",
        "average_rate": 0.68,
    },
    "GB": {
        "unit": "mi",
        "average_rate": 0.45,
    },
    "IE": {
        "unit": "km",
        "average_rate": 0.20,
    },
    "NZ": {
        "unit": "km",
        "average_rate": 0.83,
    },
    "AU": {
        "unit": "km",
        "average_rate": 0.78,
    },
}

export default (country: string): CountryMileageExpense | undefined => mileageExpense[country]