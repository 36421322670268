import type { CountryOption } from '~/types'

export default [
    { label: 'Australia', value: 'AU', icon: 'circle-flags:au', currencySymbol: '$', currencyCode: 'AUD', locale: 'en-AU' },
    { label: 'Canada', value: 'CA', icon: 'circle-flags:ca', currencySymbol: '$', currencyCode: 'CAD', locale: 'en-CA' },
    { label: 'Ireland', value: 'IE', icon: 'circle-flags:ie', currencySymbol: '€', currencyCode: 'EUR', locale: 'en-IE' },
    { label: 'New Zealand', value: 'NZ', icon: 'circle-flags:nz', currencySymbol: '$', currencyCode: 'NZD', locale: 'en-NZ' },
    { label: 'United Kingdom', value: 'GB', icon: 'circle-flags:gb', currencySymbol: '£', currencyCode: 'GBP', locale: 'en-GB' },
    { label: 'United States', value: 'US', icon: 'circle-flags:us', currencySymbol: '$', currencyCode: 'USD', locale: 'en-US' },
    { label: 'Other', value: 'XX' },
] as CountryOption[]